<template>
  <div class="QueryResult">
    <div class="QueryResult_h">
      <div class="QueryResult_bac"></div>
      <div class="QueryResult_til">
        <h4>{{ queryTitle }}</h4>
        <p>本次查询结果仅供参考，正式引用时请以官方文本为准</p>
      </div>
      <div class="QueryResult_box">
        <div class="QueryResult_content">
          <!-- 平均工资 -->
          <AverageSalary v-if="queryType==='averageSalary'" :result="queryResult"></AverageSalary>
          <!-- 企业年金 -->
          <CorporatePension v-else-if="queryType==='corporatePension'" :result="queryResult"></CorporatePension>
          <!-- 经济补偿 -->
          <DimissionAllowance v-else-if="queryType==='dimissionAllowance'" :result="queryResult"></DimissionAllowance>
          <!-- 住房公积金 -->
          <HousingFund v-else-if="queryType==='housingFund'" :result="queryResult"></HousingFund>
          <!-- 最低工资 -->
          <MinimumWage v-else-if="queryType==='minimumWage'" :result="queryResult"></MinimumWage>
          <!-- 社保缴纳 -->
          <social-security-payment v-else-if="queryType==='socialSecurityPayment'" :result="queryResult"></social-security-payment>
          <!-- 其他 -->
          <Others :result="queryResult" :class="queryType" v-else></Others>
        </div>
      </div>
    </div>
    <div class="QueryResult_feedback">
      <span @click="toFeedback">如您对数据有疑问，请反馈<van-icon name="arrow" /></span>
    </div>
    <copyrightIp></copyrightIp>
    <!-- 查询超限提示 -->
    <PopupTips :show.sync="showLimitQuery">
      <p>
        您今日或本月查询次数超限，如需相关服务，请咨询公司的<span style="color: #1789f9">客服专员</span>或在公众号<span style="color: #1789f9">回复【合作】</span>留言
      </p>
    </PopupTips>
  </div>
</template>

<script>
    import { Icon, Notify } from "vant";
    import copyrightIp from "@/components/copyrightIp";
    import AverageSalary from './components/AverageSalary'
    import CorporatePension from './components/CorporatePension'
    import DimissionAllowance from './components/DimissionAllowance'
    import HousingFund from './components/HousingFund'
    import MinimumWage from './components/MinimumWage'
    import SocialSecurityPayment from './components/SocialSecurityPayment'
    import Others from './components/Others'
    import PopupTips from "./components/PopupTips"
    import resultData2Content from './js/resultData2Content'
    import { getSalaryResult, postLog } from "@/api/salaryQuery"
    import { oauthLogin } from "@/api/PeopleCenter"
    import { validatenull } from "@/utils/validate"
    
    const titleMap = {
        corporatePension: "企业年金免税上限",
        dimissionAllowance: "经济补偿免税上限",
        housingFund: "住房公积金免税上限",
        disabledExemption: '残疾人免税政策',
        continuingEducation: '继续教育',
        housingRent: '住房租金',
        averageSalary: "平均工资",
        minimumWage: "最低工资",
        socialSecurityPayment: "社保缴纳",
        maternityLeave: '婚假产假',
        childCare: '子女护理假',
        highTemperatureAllowance: '高温津贴',
        transportationCommunication: '交通、通讯补贴',
    }
    export default {
        components: {
            [Icon.name]: Icon,
            [Notify.name]: Notify,
            copyrightIp,
            AverageSalary,
            CorporatePension,
            DimissionAllowance,
            HousingFund,
            MinimumWage,
            SocialSecurityPayment,
            Others,
            PopupTips
        },
        data() {
            return {
                queryType: '',
                queryTitle: '',
                queryResult: {},
                showLimitQuery: false,
                isShare: false
            }
        },
        beforeCreate() {
            const queryType = this.$route.params.queryType;
            document.title = titleMap[queryType]
        },
        created() {
            this.queryType = this.$route.params.queryType;
            this.isShare = validatenull(sessionStorage.getItem('isShare'));
            this.titleHandler()
            if (this.isShare) {
                this.init()
            } else {
                this.resultHandler()
            }
        },
        destroyed() {
            sessionStorage.removeItem('isShare');
        },
        methods: {
            // 获取url地址栏参数
            getUrlParam(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
                var rrr = decodeURIComponent(window.location.search);
                var r = rrr.substr(1).match(reg);
                if (r != null) return unescape(r[2]);
                return null;
            },
            // 获取微信openId
            authWxOpenId() {
                let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
                let local = encodeURIComponent(window.location.href); //获取当前url
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect`;
            },
            titleHandler() {
                this.queryTitle = titleMap[this.queryType];
            },
            // 获取openId（分享页调用）
            init() {
                if (!localStorage.getItem('querSalaryUserId'))  {
                    const code = this.getUrlParam("code"); //是否存在wx code
                    if (code) {
                        oauthLogin({ code }).then((res) => {
                            let openId = res.data.data.openid;
                            localStorage.setItem("querSalaryUserId", openId);
                            this.queryHandler();
                        });
                    } else {
                        this.authWxOpenId();
                    }
                } else {
                    this.queryHandler();
                }
            },
            resultHandler() {
                try {
                    this.queryResult = JSON.parse(sessionStorage.getItem('querSalaryResult'))
                } catch (error) {
                    this.queryResult = {}
                }
            },
            // 查询（分享页调用）
            async queryHandler() {
                try {
                    const { queryCodeId, queryType, valueCity } = this.$route.params;
                    const res = await getSalaryResult({
                        userId: localStorage.getItem("querSalaryUserId"),
                        queryFunction: queryCodeId,
                        scope: valueCity,
                        year: new Date().getFullYear(),
                    });
                    
                    if (res.data.code == 0) {
                        const result = {
                            ...res.data.data,
                            city: this.valueCity,
                            year: new Date().getFullYear(),
                        };
                        this.queryResult = result;
                        sessionStorage.setItem("querSalaryResult", JSON.stringify(result));
                        const resultData = resultData2Content(resData, queryType);
                        const logValue = validatenull(resultData) ? valueCity : resultData;
                        this.postLog('查询成功: '+ logValue);
                    } else {
                        res.data.code == 1 ? this.showLimitQuery = true : Notify(res.data.msg)
                        this.postLog('查询失败: '+ res.data.msg)
                    }
                } catch (error) {
                    this.postLog('查询失败: 未知错误');
                }
            },
            // 埋点（分享页调用）
            postLog(res) {
                const map = {
                    corporatePension: "企业年金",
                    dimissionAllowance: "经济补偿",
                    housingFund: "住房公积金",
                    disabledExemption: '残疾人',
                    continuingEducation: '继续教育',
                    housingRent: '住房租金',
                    averageSalary: "平均工资",
                    socialSecurityPayment: "社保缴纳",
                    minimumWage: "最低工资",
                    maternityLeave: '婚假产假',
                    childCare: '子女护理假',
                    highTemperatureAllowance: '高温津贴',
                    transportationCommunication: '交通、通讯补贴'
                }
                postLog({
                    userId: localStorage.getItem("querSalaryUserId"),
                    clickPlace: "查询-" + map[this.$route.params.queryType],
                    queryResult: res,
                });
            },
            toFeedback() {
                const { queryCodeId, queryType } = this.$route.params
                this.$router.push({
                    path: `/salaryQueryv1/feedback/${queryCodeId}/${queryType}`
                })
            }
        }
    };
</script>

<style lang="scss" scoped>
  .QueryResult {
    width: 100%;
    height: 100%;
    position: relative;
    .QueryResult_h {
      min-height: calc(100% - 100px);
      padding-bottom: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .QueryResult_bac {
      width: 100%;
      height: 172px;
      background-image: url("../../assets/img/query/bg2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-y: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .QueryResult_til {
      h4 {
        font-size: 20px;
        font-weight: 800;
        color: #fff;
        line-height: 1;
        padding: 24px 16px 0;
      }
      p {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1;
        padding: 12px 16px 0;
      }
    }
    .QueryResult_box {
      margin-top: 20px;
      width: 100%;
      padding: 0 10px;
      .QueryResult_content {
        width: 100%;
        background: #fff;
        border-radius: 10px;
        padding: 23px 15px;
      }
    }
    .QueryResult_feedback {
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      color: #1789F9;
    }
  }
</style>
