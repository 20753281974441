<template>
  <div class="Login">
    <div class="Login_box">
      <van-image
        class="login_logo"
        fit="cover"
        :src="require('@/assets/img/logo_phone.png')"
      />
      <div class="center">
        <van-field
          v-model="sms"
          center
          type="number"
          clearable
          ref="fieldInput"
          placeholder="请输入手机号"
          :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        >
          <template #button>
            <span class="code" v-show="timeTrue == true" @click="getCode"
              >获取验证码</span
            >
            <span class="code" v-show="timeTrue == false"
              >{{ time }}秒后重新获取</span
            >
          </template>
        </van-field>
        <van-field
          class="frame"
          v-model="phoneCode"
          maxlength="6"
          type="digit"
          placeholder="请输入验证码"
        />
      </div>
      <div class="login_but">
        <van-button type="info" @click="onLogin">登录</van-button>
      </div>
      <div class="protocol" v-if="showBtn">
        <van-checkbox
          v-model="checked"
          shape="square"
          icon-size="15px"
        ></van-checkbox>

        <div class="content">
          我已阅读并同意
          <span @click="showPopup('privacy')">《中智薪税隐私政策》</span>
          <span @click="showPopup('userProtocol')">《用户服务协议》</span>
        </div>
      </div>
      <!-- <van-popup v-model="show"
        ><div class="protocol_box">
          我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议我是XXX协议
        </div></van-popup
      > -->
    </div>
    <!-- <Protocol :checked.sync="checked" /> -->
  </div>
</template>
<script>
import {
  Image as VanImage,
  Button,
  Field,
  Checkbox,
  Notify,
  CheckboxGroup,
  Popup,
  Dialog,
} from "vant";
import { sendSMS, wxuserlogin } from "@/api/index";

import { oauthLogin } from "@/api/PeopleCenter";
// import Protocol from "./common/Protocol";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Popup.name]: Popup,
    [Notify.name]: Notify,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      sms: "",
      phoneCode: "",
      checked: true,
      timeTrue: true,
      time: 0,
      show: false,
      clientHeight: document.documentElement.clientHeight,
      showBtn: true,
      PhoneNum: 0, //发验证码次数
    };
  },
  computed: {},
  created() {
    // const locaCode = localStorage.getItem("code");
    const code = this.getUrlParam("code"); //是否存在code
    if (code) {
      oauthLogin({ code }).then((res) => {
        localStorage.setItem("code", res.data.data.openid);
        this.PhoneNum = sessionStorage.getItem("PhoneNum") || 0;
      });
    } else {
      this.CodeBtn();
    }
    // console.log(window.location);
  },
  mounted() {
    window.onresize = () => {
      if (this.clientHeight > document.documentElement.clientHeight) {
        this.showBtn = false;
      } else {
        this.showBtn = true;
      }
    };
    this.$refs.fieldInput.focus();
  },
  methods: {
    //获取url地址栏参数
    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var rrr = decodeURIComponent(window.location.search);
      var r = rrr.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 静默获取code
    CodeBtn() {
      // let appid = "wxa59bd37481626cab"; //公众平台appid
      let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
      let local = encodeURIComponent(window.location.href); //获取当前url
      //不存在就打开上面的地址进行授权
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=wecedirect`;
    },
    showPopup(type) {
      // this.show = true;
      if (type === "privacy") {
        this.$router.push("/login/privacy");
      } else {
        this.$router.push("/login/userProtocol");
      }
    },
    onLogin() {
      if (this.checked) {
        if (this.phoneCode && this.phoneCode == this.servePhoneCode) {
          let phone = this.sms;
          let openId = localStorage.getItem("code");
          let type = "4";
          let fromId = sessionStorage.getItem("fromId") || "";
          // let fromId = "53";
          wxuserlogin({ openId, phone, type, fromId }).then((res) => {
            if (res.data.data == 1) {
              localStorage.setItem("status", 102);
              sessionStorage.removeItem("PhoneNum");
              let historyRoute =
                localStorage.getItem("historyRoute") || "/peopleCenter";
              this.$router.push(historyRoute);
            } else {
              Notify("登录失败请重试！!");
            }
          });
        } else {
          return Notify("输入的验证码有误!");
        }
      } else {
        Dialog.alert({
          message: "请勾选并确认协议与条款!",
        }).then(() => {
          // on close
        });
      }
    },
    getCode() {
      if (!this.sms) {
        return Notify("手机号不能为空");
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(this.sms)) {
          let blackPhone = 0;
          this.timeTrue = false;
          if (this.PhoneNum >= 3 && this.PhoneNum <= 8) {
            this.time = 180;
          } else if (this.PhoneNum >= 20) {
            this.time = 60;
            blackPhone = 1;
          } else {
            this.time = 60;
          }

          var setTimeoutS = setInterval(() => {
            this.time--;
            if (this.time <= 0) {
              clearInterval(setTimeoutS);
              this.timeTrue = true;
            }
          }, 1000);
          let phoneNum = this.sms;
          let openId = localStorage.getItem("code");
          sendSMS({ phoneNum, openId, blackPhone }).then((res) => {
            this.servePhoneCode = res.data.data;
            this.PhoneNum++;
            sessionStorage.setItem("PhoneNum", this.PhoneNum);
          });
        } else {
          return Notify("请输入正确的手机号");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Login {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
  .Login_box {
    width: 100%;
    text-align: center;
    padding: 90px 20px 0px;
    .login_logo {
      width: 90px;
      height: 90px;
      border-radius: 14px;
      overflow: hidden;
      margin-bottom: 68px;
    }
    .center {
      padding-bottom: 45px;
      .van-cell {
        font-size: 16px;
        &::after {
          border-bottom: 1px solid #e5e5e5;
        }
      }
      .frame {
        &::before {
          position: absolute;
          box-sizing: border-box;
          content: " ";
          pointer-events: none;
          right: 16px;
          bottom: 0;
          left: 16px;
          border-bottom: 1px solid #ebedf0;
          transform: scaleY(0.5);
        }
      }
      .code {
        color: #559eec;
        font-weight: bold;
      }
    }
    .login_but {
      padding-bottom: 15px;
      opacity: 0.9;

      ::v-deep .van-button {
        width: 100%;
        font-size: 16px;
        border-radius: 5px;
        background: #549deb;
        border: 1px solid #549deb;
      }
    }
  }
}
.protocol {
  display: flex;
  width: 100%;
  padding: 25px 20px;
  font-size: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  color: #c4c3c3;
  .content {
    // color: #fff;
    padding-left: 10px;
    span {
      color: #3a82e2;
    }
  }
  .protocol_box {
    color: #333;
  }
}
</style>
