<template>
  <div class="Calculator">
    <div class="Calculator_bac"></div>
    <div class="Calculator_box">
      <div class="Calculator_title">
        <h3>薪税计算器</h3>
        <!-- <p>智能分配，为您节税</p> -->
      </div>
      <div class="Calculator_content">
        <div class="Calculator_top">
          <p>税前年终奖</p>
          <div class="Calculator_top_money">
            <span class="unit_lf">￥</span>
            <van-field
              v-model="number2"
              :readonly="isReadonly"
              :type="inputType"
              maxlength="13"
              input-align="right"
              placeholder="请输入"
              @blur="onblur"
              @focus="onFocus"
              ref="fieldInput"
            />
            <span class="unit_ri">元</span>
          </div>
        </div>
        <div class="personal">
          <van-field
            v-model="taxiValue"
            readonly
            label="个人所得税"
            input-align="right"
          />
        </div>
        <div class="ECharts_box">
          <div id="main"></div>
          <div class="total">税后年终奖<br />￥{{ afterTaxVal }}</div>
        </div>
        <div class="type_list">
          <div class="type_list_box">
            <span></span>
            <i>个人所得税</i>
          </div>
          <div class="type_list_box">
            <span></span>
            <i>税后年终奖</i>
          </div>
        </div>
        <div class="interval">
          <h3>扣除税率结果需结合年终奖漏洞区间，进行参考</h3>
          <table class="payment_ratio_table">
            <thead>
              <tr>
                <th>级数</th>
                <th class="th_w">年终奖一次性收入</th>
                <th>税率（月度）</th>
                <th>速算扣除数</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in VulnerabilityList" :key="item.id">
                <td>{{ item.sLevel }}</td>
                <td>{{ item.sBonus }}</td>
                <td>{{ item.sTaxRate * 100 }}%</td>
                <td>{{ item.sQuickCalculation }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="interval_rule">
          <h3>年终奖漏洞区间</h3>
          <table>
            <thead>
              <tr>
                <th>下限</th>
                <th>上限</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in intervalRuleList" :key="item.id">
                <td>{{ item.lowerLimit }}</td>
                <td>{{ item.upperLimit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Field, Dialog } from "vant";
import {
  findAllTTaxRate,
  findAllTLoopholeYearendbonus,
  getEquertyCalculatorGarden,
} from "@/api/Calculator";
import { findUserIntegralGarden } from "@/api/SignIn";
import * as echarts from "echarts/core";
import { TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import copyrightIp from "@/components/copyrightIp";
echarts.use([TooltipComponent, PieChart, CanvasRenderer, LabelLayout]);

export default {
  components: {
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    copyrightIp,
  },
  data() {
    return {
      inputType: "number",
      number: "",
      number2: "",
      taxiValue: "", //个人所得税
      afterTaxVal: 0, //税后年终奖
      myChart: null,
      option: null,
      VulnerabilityList: [],
      intervalRuleList: [], //漏洞
      isReadonly: false,
      moonGrade: "", // 计算器等级
    };
  },
  created() {
    this.getFindAllTTaxRate();
    this.getFindAllTLoopholeYearendbonus();
  },
  mounted() {
    this.ringEachrts();
    // 计算机权限接口
    getEquertyCalculatorGarden({ type: 2 }).then((res) => {
      let data = res.data.data;
      if (data) {
        this.moonGrade = Number(data.split("V")[1]);
      } else {
        this.moonGrade = 1;
      }
    });
  },
  beforeDestroy() {
    this.myChart = null;
  },
  methods: {
    onFocus() {
      this.isReadonly = false;
      this.inputType = "number";
      this.number2 = Number(this.number2.replace(/,/g, ""));
      
    },
    // 输入款失焦事件
    onblur() {
      this.inputType = "text";
      this.number = Number(this.number2.replace(/,/g, ""));
      this.number2 = this.toMoney(this.number2);
      let num = parseInt(this.number);
      this.VulnerabilityList.forEach((item) => {
        let min = Number(item.sLowerValue);
        let max = Number(item.sUpperValue);
        if (num >= min && num <= max) {
          this.taxiValue = num * item.sTaxRate - item.sQuickCalculation;
          this.taxiValue = Math.round(this.taxiValue * 100) / 100;

          return;
        }
      });
      this.option.series[0].data[0].value = this.taxiValue;
      this.option.series[0].data[1].value = this.number;
      this.option && this.myChart.setOption(this.option);
      this.afterTaxVal = this.number - this.taxiValue;
      this.taxiValue = this.taxiValue.toLocaleString("zh", {
        style: "decimal",
        minimumFractionDigits: 2,
      });
      this.afterTaxVal = this.afterTaxVal.toLocaleString("zh", {
        style: "decimal",
        minimumFractionDigits: 2,
      });
      this.intervalRuleList.forEach((item) => {
        if (
          this.number >= Number(item.lowerLimit) &&
          this.number <= Number(item.upperLimit)
        ) {
          Dialog.alert({
            message:
              "您输入的年终奖金额处于年终奖漏洞区间范围，将会影响扣除税率结果，建议结合下方表格进行参考。",
          }).then(() => {});
        }
      });
    },
    toMoney(num) {
      num = num.replace(/,/g, "");
      let money = Number(num);
      // money = money.toFixed(2);

      // money = parseFloat(money);

      money = money.toLocaleString("zh", {
        style: "decimal",
        minimumFractionDigits: 2,
      });
      return money; // 返回的是字符串23,245.12保留2位小数
    },
    ringEachrts() {
      var chartDom = document.getElementById("main");
      this.myChart = echarts.init(chartDom);
      this.option = {
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 1,
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "10",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "个人所得税",
                itemStyle: {
                  color: "#91CC75",
                },
              },
              {
                value: 100,
                name: "税后年终奖",
                itemStyle: {
                  color: "#FC5D62",
                },
              },
            ],
          },
        ],
      };

      this.option && this.myChart.setOption(this.option);
    },
    getFindAllTTaxRate() {
      findAllTTaxRate().then((res) => {
        this.VulnerabilityList = res.data.data;
      });
    },
    getFindAllTLoopholeYearendbonus() {
      findAllTLoopholeYearendbonus().then((res) => {
        this.intervalRuleList = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Calculator {
  width: 100%;
  position: relative;
  // padding-bottom: 70px;
  .Calculator_bac {
    width: 100%;
    height: 250px;
    background-image: url("../../../assets/img/Utilities1_Moon_01.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .Calculator_box {
    width: 100%;
    padding: 10px;
    .Calculator_title {
      color: #fff;
      h3 {
        font-size: 20px;
        font-weight: bold;
      }
      p {
        font-size: 12px;
        padding-bottom: 5px;
      }
    }
    .Calculator_content {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
    }
  }
}
.Calculator_top {
  width: 100%;
  background: #fff;
  box-sizing: border-box;

  font-weight: bold;
  .Calculator_top_money {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .unit_lf {
      font-size: 35px;
    }
    .unit_ri {
      font-size: 18px;
      min-width: 38px;
      color: #999999;
    }
    ::v-deep .van-cell {
      padding-left: 3px;
      .van-cell__value {
        font-size: 25px;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
        font-size: 25px;
        font-weight: 100;
        line-height: 80px;
      }
    }
  }
}
.personal {
  border-bottom: 1px solid #efecec;
  .van-cell {
    font-weight: bold;
  }
}
.ECharts_box {
  display: flex;
  align-items: center;
  #main {
    width: 180px;
    min-width: 180px;
    height: 180px;
  }
  .total {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
}
.type_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  .type_list_box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding-bottom: 5px;
    span {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 2px;
    }
    &:nth-child(1) {
      span {
        background: #84c961;
      }
    }
    &:nth-child(2) {
      span {
        background: #fc5d62;
      }
    }
  }
}
.interval {
  width: 100%;
  padding: 10px 0;
  h3 {
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
  }
  .payment_ratio_table {
    width: 100%;
    thead {
      font-size: 12px;
      background: #3d8df7;
      color: #fff;
      tr {
        line-height: 30px;
        th {
          text-align: center;
          &:nth-child(1) {
            padding-left: 5px;
          }
        }
      }
      .th_w {
        width: 140px;
      }
    }
    tbody {
      font-size: 14px;
      tr {
        border-bottom: 1px solid #efecec;

        td {
          padding: 6px 0;
          text-align: center;
        }
      }

      .total {
        color: #327ff9;
      }
    }
  }
}
.interval_rule {
  width: 100%;
  padding: 10px 0;
  h3 {
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
  }
  table {
    width: 100%;
    border: solid #ebedf0;
    border-width: 1px 0px 0px 1px;
    th,
    td {
      text-align: center;
      line-height: 24px;
      border: solid #ebedf0;
      border-width: 0px 1px 1px 0px;
    }
  }
}
</style>
