<template>
  <div class="Login">
    <div class="Login_box">
      <van-image
        class="login_logo"
        fit="cover"
        :src="require('@/assets/img/login_logo.png')"
      />
      <div class="login_but">
        <van-button type="info" @click="goLogin('phone')"
          >手机号登陆/注册</van-button
        >
      </div>
      <div class="login_but">
        <van-button type="primary" @click="goLogin('wx')"
          >微信用户一键登录</van-button
        >
      </div>
    </div>
    <Protocol :checked.sync="checked" />
  </div>
</template>
<script>
import { Image as VanImage, Button, Dialog } from "vant";
import Protocol from "./common/Protocol";
import { getCodeInfo } from "@/api/index";
import { oauthLogin, findUserByOpenId } from "@/api/PeopleCenter";
// import { wxLogin } from "../../utils/login";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    Protocol,
  },
  data() {
    return {
      checked: false,
      activeClick: false, //是否点击过
    };
  },
  created() {},
  mounted() {
    const code = this.getUrlParam("code"); //是否存在code
    if (code) {
      oauthLogin({ code }).then((res) => {
        let openId = res.data.data.openid;
        localStorage.setItem("code", openId);
        findUserByOpenId({ openId }).then((res) => {
          if (res.data.data.userPhone) {
            localStorage.setItem("status", 102);
            let historyRoute =
              localStorage.getItem("historyRoute") || "/peopleCenter";
            let href = window.location.href;
            let search = window.location.search
              ? window.location.search + "#/login"
              : "/login";
            let str = href.replace(search, "") + "#" + historyRoute;
            window.location.href = str;
          } else {
            let href = window.location.href;
            let search = window.location.search;
            let str = href.replace(search, "") + "/phone";
            window.location.href = str;
          }
        });

        // this.$router.push("/login/phone");
      });
    }
  },
  methods: {
    goLogin(type) {
      if (this.checked) {
        if (type === "phone") {
          this.$router.push("/login/phone");
        } else {
          this.wxLogin();
        }
      } else {
        Dialog.alert({
          message: "请勾选并确认协议与条款!",
        }).then(() => {
          // on close
        });
      }
    },
    wxLogin() {
      // let appid = "wxa59bd37481626cab"; //公众平台appid
      let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
      let local = encodeURIComponent(window.location.href); //获取当前url
      //不存在就打开上面的地址进行授权
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect`;
    },
    // 静默获取code
    CodeBtn() {
      // let appid = "wxa59bd37481626cab"; //公众平台appid
      let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
      let local = encodeURIComponent(window.location.href); //获取当前url
      //不存在就打开上面的地址进行授权
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=wecedirect`;
    },
    // 获取url地址栏参数

    getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");

      var rrr = decodeURIComponent(window.location.search);

      var r = rrr.substr(1).match(reg);

      if (r != null) return unescape(r[2]);

      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.Login {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/img/login_bac.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .Login_box {
    width: 100%;
    text-align: center;

    padding: 90px 20px 0px;
    .login_logo {
      width: 90px;
      height: 90px;
      border-radius: 14px;
      overflow: hidden;
      margin-bottom: 205px;
    }
    .login_but {
      padding-bottom: 15px;
      opacity: 0.9;

      ::v-deep .van-button {
        width: 100%;
        font-size: 16px;
        border-radius: 8px;
      }
    }
  }
}
</style>
