export default (result, queryType) => {
  // 平均工资
  if(queryType == 'averageSalary') {
    const arrStr = result.resultAvgList.map(avg => {
      return `${avg.zgswjg ? avg.zgswjg+'地区' :''}目前执行标准年度平均工资为${avg.sndpjgz}元，月度平均工资为${avg.ypjgz}元`
    })
    return arrStr.join('；')
  }

  // 企业年金
  if(queryType == 'corporatePension') {
    const arrStr = result.resultAnnuityList.map(avg => {
      return `${avg.zgswjg ? avg.zgswjg+'地区' :''} 目前执行标准企业年金免税上限为${avg.njsx}元`
    })
    return arrStr.join('；')
  }

  // 经济补偿
  if(queryType == 'dimissionAllowance') {
    const arrStr = result.dimissionAllowanceList.map(avg => {
      return `目前执行标准经济补偿免税上限为${avg.dimissionAllowance}元`
    })
    return arrStr.join('；')
  }

  // 住房公积金
  if(queryType == 'housingFund') {
    const arrStr = result.resultGongjijinList.map(avg => {
      return `${avg.zgswjg ? avg.zgswjg+'地区' :''}目前执行标准住房公积金免税上限为${avg.zfgjjsx}元`
    })
    return arrStr.join('；')
  }

  // 最低工资
  if (queryType == 'minimumWage') {
    const arrStr = result.reusltMinList.map(avg => {
      return `目前执行标准${avg.county||''}月最低工资为${avg.yearMinWage}元，小时最低工资为${avg.hourMinWage}元`
    })
    return arrStr.join('；')
  }
}