<template>
  <div class="protocol">
    <van-checkbox
      v-model="ProtocolChecked"
      shape="square"
      icon-size="15px"
      @click="abc"
    ></van-checkbox>

    <div class="content">
      我已阅读并同意
      <span @click="showPopup('privacy')">《中智薪税隐私政策》</span>
      <span @click="showPopup('userProtocol')">《用户服务协议》</span>
    </div>
  </div>
</template>
<script>
import { Checkbox, CheckboxGroup } from "vant";
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      ProtocolChecked: false,
    };
  },
  watch: {
    checked: {
      immediate: true,
      handler() {
        this.ProtocolChecked = this.checked;
      },
    },
  },
  methods: {
    abc() {
      this.$emit("update:checked", this.ProtocolChecked);
    },
    showPopup(type) {
      if (type === "privacy") {
        this.$router.push("/login/privacy");
      } else {
        this.$router.push("/login/userProtocol");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.protocol {
  display: flex;
  width: 100%;
  padding: 25px 20px;
  font-size: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  // color: #c4c3c3;
  .content {
    color: #fff;
    padding-left: 10px;
    span {
      // color: #3a82e2;
    }
  }
}
</style>
