<template>
  <div class="QueryResult">
    <div class="QueryResult_h">
      <div class="QueryResult_bac"></div>
      <div class="QueryResult_til">
        <h4>{{ queryResult.title }}</h4>
        <p>各地区申报条件及流程有差异，本次查询仅供参考</p>
      </div>
      <div class="QueryResult_box">
        <div class="QueryResult_content">
          <Others :result="queryResult" :class="queryType"></Others>
        </div>
      </div>
    </div>
    <div class="QueryResult_feedback">
      <span @click="toFeedback">如您想咨询此政策，欢迎与我们联系<van-icon name="arrow" /></span>
    </div>
    <copyrightIp></copyrightIp>
    <!-- 查询超限提示 -->
    <PopupTips :show.sync="showLimitQuery">
      <p>
        您今日或本月查询次数超限，如需相关服务，请咨询公司的<span style="color: #1789f9">客服专员</span>或在公众号<span style="color: #1789f9">回复【合作】</span>留言
      </p>
    </PopupTips>
  </div>
</template>

<script>
    import { Icon, Notify } from "vant";
    import copyrightIp from "@/components/copyrightIp";
    import Others from './components/Others'
    import PopupTips from "./components/PopupTips"
    import { getWelfareDetail, postLog } from "@/api/salaryQuery"
    import { oauthLogin } from "@/api/PeopleCenter"
    import { validatenull } from "@/utils/validate"
    export default {
        components: {
            [Icon.name]: Icon,
            [Notify.name]: Notify,
            copyrightIp,
            Others,
            PopupTips
        },
        data() {
            return {
                id: '',
                queryType: '',
                queryTitle: '',
                queryResult: {},
                showLimitQuery: false,
                isShare: false
            }
        },
        created() {
            const { id, queryCodeId, queryType } = this.$route.params;
            this.id = id;
            this.queryCodeId = queryCodeId;
            this.queryType = queryType;
            this.isShare = validatenull(sessionStorage.getItem('isShare'));
            if (this.isShare) {
                this.init()
            } else {
                this.resultHandler()
            }
        },
        destroyed() {
            sessionStorage.removeItem('isShare');
        },
        methods: {
            // 获取url地址栏参数
            getUrlParam(name) {
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
                var rrr = decodeURIComponent(window.location.search);
                var r = rrr.substr(1).match(reg);
                if (r != null) return unescape(r[2]);
                return null;
            },
            // 获取微信openId
            authWxOpenId() {
                let appid = "wx2ddd42d0d9eb53da"; //公众平台appid (中)
                let local = encodeURIComponent(window.location.href); //获取当前url
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect`;
            },
            // 获取openId（分享页调用）
            init() {
                if (!localStorage.getItem('querSalaryUserId'))  {
                    const code = this.getUrlParam("code"); //是否存在wx code
                    if (code) {
                        oauthLogin({ code }).then((res) => {
                            let openId = res.data.data.openid;
                            localStorage.setItem("querSalaryUserId", openId);
                            this.queryHandler();
                        });
                    } else {
                        this.authWxOpenId();
                    }
                } else {
                    this.queryHandler();
                }
            },
            resultHandler() {
                try {
                    this.queryResult = JSON.parse(sessionStorage.getItem('querSalaryResult'))
                } catch (error) {
                    this.queryResult = {}
                }
            },
            // 查询（分享页调用）
            async queryHandler() {
                try {
                    const res = await getWelfareDetail({
                        userId: localStorage.getItem("querSalaryUserId"),
                        id: this.id
                    });
                    
                    if (res.data.code == 0) {
                        const resData = res.data.data;
                        const result = {
                            ...resData
                        };
                        this.queryResult = result;
                        sessionStorage.setItem("querSalaryResult", JSON.stringify(result));
                        this.postLog(`查询成功:${ result.title }； 分享链接查询`);
                    } else {
                        res.data.code == 1 ? this.showLimitQuery = true : Notify(res.data.msg)
                        this.postLog('查询失败: '+ res.data.msg)
                    }
                } catch (error) {
                    console.log(error, 'error')
                    this.postLog('查询失败: 未知错误');
                }
            },
            // 埋点（分享页调用）
            postLog(res) {
                const map = {
                    welfare: "优惠政策"
                }
                postLog({
                    userId: localStorage.getItem("querSalaryUserId"),
                    clickPlace: "查询-" + map[this.queryType],
                    queryResult: res,
                });
            },
            toFeedback() {
                this.$router.push({
                    path: `/salaryQueryv1/feedback/${this.queryCodeId}/${this.queryType}`
                })
            }
        }
    };
</script>

<style lang="scss" scoped>
  .QueryResult {
    width: 100%;
    height: 100%;
    position: relative;
    .QueryResult_h {
      min-height: calc(100% - 100px);
      padding-bottom: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .QueryResult_bac {
      width: 100%;
      height: 172px;
      background-image: url("../../assets/img/query/bg2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-y: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .QueryResult_til {
      h4 {
        font-size: 20px;
        font-weight: 800;
        color: #fff;
        line-height: 1.2;
        padding: 24px 16px 0;
      }
      p {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1;
        padding: 12px 16px 0;
      }
    }
    .QueryResult_box {
      margin-top: 20px;
      width: 100%;
      padding: 0 10px;
      .QueryResult_content {
        width: 100%;
        background: #fff;
        border-radius: 10px;
        padding: 23px 15px;
      }
    }
    .QueryResult_feedback {
      font-size: 13px;
      line-height: 30px;
      text-align: center;
      color: #1789F9;
    }
  }
</style>
