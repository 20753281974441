<!--
 * @Author: your name
 * @Date: 2022-04-11 18:29:16
 * @LastEditTime: 2022-04-12 10:09:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ciic-web-h5\src\views\Model\M6.vue
-->
<template>
  <div class="M6">
    <div class="M6_top">
      <van-image fit="cover" :src="topImg" />
    </div>
    <div class="M6_main" id="myDom">
      <van-button type="primary" size="small" @click="onclickShare">{{
        text
      }}</van-button>
    </div>
    <transition name="van-fade">
      <div class="mcover" v-show="mcover">
        <img src="../../assets/img/guide.png" />
      </div>
    </transition>
    <copyrightIp class="copyrightIp" />
  </div>
</template>
<script>
import { Image as VanImage, Button } from "vant";
import { getTSpecificById, addmodel3 } from "@/api/model";
import getJSSDK from "@/utils/wxShare";
import copyrightIp from "@/components/copyrightIp";
import { findUserByOpenId } from "@/api/PeopleCenter";
export default {
  components: {
    VanImage,
    [Button.name]: Button,
    copyrightIp,
  },
  data() {
    return {
      topImg: "",
      mcover: false,
      text: "分享邀请", // 去注册
      wxId: "",
    };
  },
  methods: {
    getTSpecific() {
      // let tspecificId = window.location.hash.split("?")[1].split("=")[1];
      let tspecificId = this.$route.query.id;
      getTSpecificById({ tspecificId }).then((res) => {
        this.topImg = res.data.data.tspecificTempItems[0]?.itemValue;
        // let code = localStorage.getItem("code");
        let link = `${this.$route.fullPath}&fromId=${this.wxId}`;
        getJSSDK({
          title: "关注中智薪税   惊喜享不停",
          // desc: "关注中智薪税   惊喜享不停",
          link: link,
        });
      });
    },

    onclickShare() {
      if (this.text === "分享邀请") {
        this.mcover = true;
        let _this = this;
        setTimeout(() => {
          _this.mcover = false;
        }, 3000);
      } else {
        this.$router.push("login");
      }
    },
  },
  mounted() {
    let status = localStorage.getItem("status");
    if (status == 102) {
      this.text = "分享邀请";
      let openId = localStorage.getItem("code") || "";
      findUserByOpenId({ openId }).then((res) => {
        console.log(
          "%c [ res ]-84",
          "font-size:13px; background:pink; color:#bf2c9f;",
          res.data.data.wxId
        );
        this.wxId = res.data.data.wxId;
      });
    } else {
      this.text = "去注册";
    }
    let fromId = this.$route.query.fromId || "";
    if (fromId) sessionStorage.setItem("fromId", fromId);
    this.getTSpecific();
  },
};
</script>
<style lang="scss" scoped>
.M6 {
  position: relative;
  padding-bottom: 50px;

  .copyrightIp {
    width: 100%;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
}
.M6_top {
  width: 100%;
  height: calc(100vh - 72px);
  // overflow: hidden;
  .van-image {
    width: 100%;
    height: 100%;
    // height: calc(100% - 40px);
    // min-height: 350px;
  }
}
.M6_main {
  width: 100%;
  background: #fff;
  padding-bottom: 1px;
  text-align: center;
  padding: 15px 0 30px;
  .van-button {
    width: 120px;
    background: #4899d0;
    border: 1px solid #4899d0;
  }
}
.mcover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20000;
}
.mcover img {
  position: fixed;
  right: 18px;
  top: 5px;
  width: 260px;
  height: 180px;
  z-index: 999;
}
</style>
