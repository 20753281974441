<template>
  <div class="SalaryQuery_component">
    <!-- 单个显示 -->
    <div class="SalaryQueryResult_singleShow" v-if="result.resultLeaveList && result.resultLeaveList.length">
      <h3 class="title">{{ result.city }}</h3>
      <div class="cont" v-for="(avg, idx) in result.resultLeaveList" :key="idx">
        目前执行标准经济补偿免税上限为
        <b class="num">{{ avg.dimissionAllowance }}</b>元
      </div>
    </div>

    <!-- 缺省 -->
    <div v-else class="SalaryQueryResult_empty">
      <van-empty description="暂无该地区数据" />
    </div>

    <!--注释 -->
    <div class="tips">
      注释:
      经济补偿金是劳动者因与用人单位解除或终止劳动关系而取得的一次性补偿收入，包括用人单位发放的经济补偿金、生活补助费和其他补助费用，根据《劳动合同法》的相关规定，劳动者月工资高于用人单位所在直辖市、设区的市级人民政府公布的本地区上年度职工月平均工资三倍的，向其支付经济补偿的标准按职工月平均工资3倍的数额支付，年限最高不超过12年。
    </div>
  </div>
</template>

<script>
import { Empty } from "vant";
export default {
  props: {
    queryInfo: Object,
    result: Object
  },
  components: {
    [Empty.name]: Empty,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
</style>
